/*
 * Application global variables.
 */

// Set Font Awesome font path
$fa-font-path: '@fortawesome/fontawesome-free/webfonts';

// ---------------------------------------------------------------------------
// Bootstrap variables
//
// Override Bootstrap variables here to suite your theme.
// Copy variables you want to customize from node_modules/bootstrap/scss/_variables.scss

//
// Color system
//

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$primary: #0077ff; // Electric Blue for primary actions
$secondary: #6610f2; // Neon Green for secondary information
$blue: #0073dd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$theme-colors: (
  primary: $blue,
  secondary: $gray-600,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800,
);

// Define typography
$base-font-family: 'Open Sans', sans-serif; // Choose a web-safe font
$heading-font-family: 'Helvetica', sans-serif; // A different font for headings
// Custom Variables
$font-family-headings: 'Roboto', sans-serif; // Headings font

// Define spacing scale
$spacing-1: 0.25rem;
$spacing-2: 0.5rem;
$spacing-3: 1rem;
$spacing-4: 1.5rem;
$spacing-5: 3rem;

// Forms
input,
select,
textarea {
  border-radius: 0.25rem; // Consistent border-radius with buttons
  padding: $spacing-3; // Comfortable padding inside form fields

  &:focus {
    border-color: $primary;
    box-shadow: 0 0 0 0.2rem rgba($primary, 0.25);
  }
}
$text-color: #212529; // Default text color
$background-color: #f8f9fa; // A light gray background color for subtle contrast

// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-headings;
  font-weight: 600; // Slightly bolder headings for clear hierarchy
}

body {
  font-family: $base-font-family;
  color: $text-color;
  background-color: $background-color;
}

// Use Bootstrap defaults for other variables, imported here so we can access all app variables in one place when used
// in components.
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
