@import '../../../scss//scss/theme/theme-variables.scss';
// Variables
$primary-color: #007bff;
$secondary-color: #6c757d;
$background-color: #f8f9fa;
$text-color: #212529;
// Booking Form Styles
.booking-form {
  .card-header {
    background-color: $primary-color;
    color: $white;
  }

  .form-step {
    margin-bottom: 1.5rem;

    h3 {
      color: $secondary-color;
    }

    .custom-select {
      width: 100%;
      margin-top: 0.5rem;
    }

    .btn-primary {
      margin-top: 1rem;
    }
  }
}
.slot-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  .btn {
    padding: 0.375rem 0.75rem;
    // Additional button styling
  }
}
