@use '../custom' as *;

@use '../components/mixins' as *;
@import '../../../scss//scss/theme/theme-variables.scss';

// Buttons
.btn {
  border-radius: 0.25rem; // Rounded corners for buttons
  padding: $spacing-3 $spacing-4; // Custom padding for larger click area

  &.btn-primary {
    background-color: $primary;
    &:hover {
      background-color: darken($primary, 10%);
    }
  }

  &.btn-secondary {
    background-color: $secondary;
    &:hover {
      background-color: darken($secondary, 10%);
    }
  }
}
// .btn-rounded {
//   position: relative;
//   padding: 0.8375rem 4.375rem 0.8375rem 1.25rem;
//   font-weight: 700;
//   margin: 0;
//   border: 0.1rem solid rgba($white, 0.6);
//   background-color: $white;
//   display: block;

//   span {
//     position: absolute;
//     background-color: $primary;
//     width: 2.5rem;
//     height: 2.5rem;
//     top: 50%;
//     right: 5px;
//     transform: translateY(-50%);
//     border-radius: 50%;

//     i {
//       color: $white;
//       font-size: 1rem;
//       line-height: 2.5rem;
//     }
//   }

//   &:hover {
//     @include gradient;
//     color: $white;

//     span {
//       background-color: rgba($black, 0.6);
//     }
//   }
// }
