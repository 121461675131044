@use '../custom' as *;
@use '../components/mixins' as *;

.intro-section {
  @include gradientPurple;
  padding: 10rem 0 0 0;
  width: 100%;
  height: 100%;
}

.intro-section-white {
  background-color: $white;
  padding: 10rem 0 0 0;
  width: 100%;
  height: 100%;
}

.intros {
  .video-box {
    position: relative;
    background-image: url('../../../images/pexels-photo-1552242.png');
    height: 100%;
    // background-image: url('../../hero-image.jpg');
    height: 100%;
    background-size: cover;
    background-position: center;
    a {
      @include absoluteCenter;

      span {
        i {
          font-size: 3rem;
          color: $secondary;
        }
      }
    }
  }
}

.border-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 6rem;
  height: 6rem;
  border: 0.55rem solid $white;
  border-radius: 50%;
  animation: pulse-border 1.5s linear infinite;

  &--border-1 {
    animation-delay: 1s;
  }
  &--border-2 {
    animation-delay: 1.5s;
  }
}
