/* You can add global styles to this file, and also import other style files */

@use './assets/scss/custom/components/animations';
@use './assets/scss/custom/components/buttons';
@use './assets/scss/custom/components/mixins';
@use './assets/scss/custom/components/typography';
@use './assets/scss/custom/fontawesome';

//import sections
@use './assets/scss/custom/sections/navbar';
@use './assets/scss/custom/sections/intro-section';
// @use './assets/scss/custom/sections/companies';
// @use './assets/scss/custom/sections/services';
// @use './assets/scss/custom/sections/testimonials';
// @use './assets/scss/custom/sections/faq';
// @use './assets/scss/custom/sections/portfolio';
// @use './assets/scss/custom/sections/get-started';
@use './assets/scss/custom/sections/calendar';
@use './assets/scss/custom/sections/footer';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import './assets/scss/custom/custom';
// @import '~bootstrap/dist/css/bootstrap.min.css';
// @import '~bootstrap-icons/font/bootstrap-icons.css';

.logo {
  height: 100px;
}

// body {
//   margin: 0;
// }

// // Define the primary colors based on the design
// $primary-color: #150c53; // Greenish color, change as per design
// $secondary-color: #c5309b; // Darker green, change as per design
// $text-color: #ffffff; // White text color
// $bg-color: #e0f2f1; // Light background color

// // Override Bootstrap variables
// $body-bg: $bg-color;
// $headings-color: $text-color;
// $headings-font-weight: 600; // Assuming a bolder font-weight is needed

// // Define the primary colors based on the design
// // $primary-color: #00bfa5; // Teal-like shade
// // $secondary-color: #005f56; // Darker teal shade
// $text-color: #ffffff; // White text color
// $bg-color: #e0f2f1; // Light background color

// // Assuming you have Bootstrap included

// // Override Bootstrap variables
// $body-bg: $bg-color;
// $headings-color: $text-color;
// $headings-font-weight: 600; // Assuming a bolder font-weight is needed

// // Custom card styling
// .card-event {
//   background: $primary-color;
//   color: $text-color;
//   border: none;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

//   .card-header {
//     background: $secondary-color;
//   }

//   .card-title {
//     font-size: 1.5rem;
//   }

//   .card-content {
//     font-size: 1rem;
//   }
// }

// // Custom button styling
// .btn-custom {
//   background-color: $secondary-color;
//   border-color: $secondary-color;
//   color: $text-color;

//   &:hover {
//     background-color: darken($secondary-color, 10%);
//     border-color: darken($secondary-color, 10%);
//   }
// }
